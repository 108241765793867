import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from "./StateProvider";
import axios from 'axios';

const FIREBASE_FUNCTIONS_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

const StripeConnectSetup = () => {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSetupStripeConnect = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      console.log('Calling createStripeConnectAccount function...');
      const createResponse = await axios.get(`${FIREBASE_FUNCTIONS_URL}/createStripeConnectAccount?email=${user.email}`);
      console.log('Response from createStripeConnectAccount:', createResponse.data);
      
      if (createResponse.data.success) {
        console.log('Redirecting to:', createResponse.data.onboardingUrl);
        window.location.href = createResponse.data.onboardingUrl;
      } else {
        throw new Error(createResponse.data.error || 'Failed to create Stripe Connect account');
      }
    } catch (error) {
      console.error('Error setting up Stripe Connect:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      alert(`An error occurred while setting up Stripe Connect. Please check the console for more details.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Set up Stripe Connect</h2>
      <p>Click the button below to set up your Stripe Connect account. You'll be redirected to Stripe to complete the process.</p>
      <button onClick={handleSetupStripeConnect} disabled={isLoading}>
        {isLoading ? 'Setting up...' : 'Set up Stripe Connect'}
      </button>
    </div>
  );
};

export default StripeConnectSetup;
