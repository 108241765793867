import React, { useEffect, useState, useCallback } from 'react';
import './Order.css';
import moment from "moment";
import CheckoutProduct from './CheckoutProduct';
import CurrencyFormat from 'react-currency-format';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { db } from "./firebase";
import { useStateValue } from './StateProvider';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import DOMPurify from 'dompurify';

function Order({ order, hideCheckbox, collectionName }) {
  console.log('Order component rendering', { orderId: order.id, orderData: order.data });

  const [{ user }, dispatch] = useStateValue();
  const [isOrderClosed, setIsOrderClosed] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderStatus, setOrderStatus] = useState(order.data.status);
  const [statusMessage, setStatusMessage] = useState('');
  const [selectedRating, setSelectedRating] = useState(null);
  const [lastOrderCloseTime, setLastOrderCloseTime] = useState(0);

  console.log('Initial state', { isOrderClosed, showConfirmation, orderStatus, statusMessage, selectedRating, lastOrderCloseTime });

  useEffect(() => {
    console.log('Order useEffect triggered', { orderId: order.id, userEmail: user?.email, buyerEmail: order.data.buyerEmail });

    if (user?.email !== order.data.buyerEmail) {
      console.error('Unauthorized access attempt', { userEmail: user?.email, buyerEmail: order.data.buyerEmail });
      return;
    }

    const sellerOrderRef = db.collection('users').doc(order.data.basket[0]?.sellerEmail).collection('soldOrders').doc(order.id);

    const unsubscribe = sellerOrderRef.onSnapshot(doc => {
      console.log('Seller order snapshot updated', { orderId: order.id, exists: doc.exists, data: doc.data() });
      if (doc.exists) {
        const status = doc.data().status;
        setOrderStatus(status);

        if (status === 'Pending') {
          setStatusMessage('This order is pending.');
        } else if (status === 'Completed') {
          setStatusMessage('This order is completed.');
        }
      }
    });

    return () => {
      console.log('Unsubscribing from seller order snapshot', { orderId: order.id });
      unsubscribe();
    };
  }, [order.id, order.data.basket, user, order.data.buyerEmail]);

  const handleCloseOrder = () => {
    console.log('handleCloseOrder called', { isOrderClosed, orderId: order.id });
    if (!isOrderClosed) {
      console.log('Opening confirmation dialog', { orderId: order.id });
      setShowConfirmation(true);
    }
  };

  const handleError = useCallback((error, userMessage) => {
    console.error('handleError called', { error, userMessage });
    alert(userMessage || 'An error occurred. Please try again later.');
  }, []);

  const confirmCloseOrder = async () => {
    console.log('confirmCloseOrder called', { orderId: order.id, orderData: order.data });
  
    if (!order || !order.id || !order.data) {
      console.error('Invalid order data:', order);
      alert('Invalid order data. Please try again.');
      return;
    }
  
    const now = Date.now();
    console.log('Checking cooldown period', { lastOrderCloseTime, now, difference: now - lastOrderCloseTime });
  
    if (now - lastOrderCloseTime < 60000) {
      console.log('Cooldown period not elapsed');
      alert('Please wait before closing another order.');
      return;
    }
  
    const buyerEmail = user?.email;
    let sellerEmail = order.data.sellerEmail; // This should be stored in the order data
  
    console.log('Buyer and seller emails', { buyerEmail, sellerEmail });
  
    if (!buyerEmail || !sellerEmail) {
      console.error('Missing buyer or seller email', { buyerEmail, sellerEmail });
      alert('Missing buyer or seller email. Please try again.');
      return;
    }
  
    const openOrdersRef = db.collection('users').doc(buyerEmail).collection('orders');
    const closedOrdersRef = db.collection('users').doc(buyerEmail).collection('closedOrders');
    const sellerUserRef = db.collection('users').doc(sellerEmail);
  
    try {
      console.log('Starting order closure process');
  
      // 1. Move order to closed orders (buyer's side)
      const openOrderDoc = await openOrdersRef.doc(order.id).get();
      if (openOrderDoc.exists) {
        const orderData = openOrderDoc.data();
        const sellerOrderId = orderData.sellerOrderId; // Get the seller's order ID
  
        await closedOrdersRef.doc(order.id).set({
          ...orderData,
          status: 'Completed',
          rating: selectedRating || null,
        });
        await openOrdersRef.doc(order.id).delete();
        console.log('Order moved to buyer\'s closed orders');
  
        // 2. Update seller's sold order
        const sellerOrderRef = db.collection('users').doc(sellerEmail).collection('soldOrders').doc(sellerOrderId);
        await sellerOrderRef.update({ 
          status: 'Completed', 
          rating: selectedRating || null 
        });
        console.log('Seller\'s sold order updated to Completed');
  
        // 3. Update seller rating
        const sellerDoc = await sellerUserRef.get();
        console.log('Seller document retrieved', { exists: sellerDoc.exists, data: sellerDoc.data() });
        if (sellerDoc.exists) {
          let ordersSoldWithRating = sellerDoc.data()?.ordersSoldWithRating || 0;
          let averageRating = sellerDoc.data()?.averageRating || 0;
  
          if (selectedRating !== null) {
            ordersSoldWithRating++;
            const totalRatings = (averageRating * (ordersSoldWithRating - 1)) + selectedRating;
            averageRating = parseFloat((totalRatings / ordersSoldWithRating).toFixed(2));
          }
  
          console.log('Updating seller rating', { ordersSoldWithRating, averageRating });
          await sellerUserRef.set({ ordersSoldWithRating, averageRating }, { merge: true });
          console.log('Seller rating updated');
        } else {
          console.error('Seller document does not exist');
          throw new Error('Seller document does not exist');
        }
  
        // 4. Trigger the payout to the seller
        console.log('Attempting to trigger payout');
        const idToken = await user.getIdToken();
        const response = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/triggerPayout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            sellerEmail: sellerEmail,
            subtotal: order.data.subtotal,
            orderId: sellerOrderId, // Use the seller's order ID here
          }),
        });
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Payout trigger failed with status: ${response.status}. Error: ${errorText}`);
          throw new Error(`Payout trigger failed with status: ${response.status}. ${errorText}`);
        } else {
          const responseData = await response.text();
          console.log('Payout API response data:', responseData);
          console.log('Payout successfully completed');
        }
  
        setIsOrderClosed(true);
        setOrderStatus('Completed');
        setShowConfirmation(false);
        setLastOrderCloseTime(now);
  
        console.log('Order closure process completed successfully');
        alert('Order closed successfully!');
      } else {
        console.error('Open order document does not exist');
        throw new Error('Open order document does not exist');
      }
    } catch (error) {
      console.error('Detailed error in confirmCloseOrder:', error);
      
      // Check if the order was moved to closed orders
      const closedOrderDoc = await closedOrdersRef.doc(order.id).get();
      if (closedOrderDoc.exists) {
        alert(`Order moved to closed orders, but an error occurred: ${error.message}. Please contact support.`);
      } else {
        handleError(error, `Failed to complete the order closure process: ${error.message}`);
      }
    }
  };

  const cancelCloseOrder = () => {
    console.log('cancelCloseOrder called');
    setSelectedRating(null);
    setShowConfirmation(false);
  };

  const handleRatingClick = (rating) => {
    console.log('handleRatingClick called', { rating });
    if (rating >= 1 && rating <= 5) {
      setSelectedRating(rating);
    } else {
      console.error('Invalid rating');
    }
  };

  console.log('Rendering Order component', { orderId: order.id, orderStatus, isOrderClosed });

  return (
    <div className='order'>
      <h2>Order</h2>
      <p>{moment.unix(order.data.created).format("MMMM Do YYYY, h:mma")}</p>
      <p className='order__id'>
        <small>{DOMPurify.sanitize(order.id)}</small>
      </p>
      <p>Status: {orderStatus}</p>
      {orderStatus === 'Pending' && <p>This order is pending.</p>}
      {orderStatus === 'Completed' && <p>This order is completed.</p>}
      {order.data.basket?.map(item => (
        <CheckoutProduct
          key={item.id}
          id={item.id}
          title={DOMPurify.sanitize(item.title)}
          image={item.image}
          price={item.price}
          rating={item.rating}
          seller={DOMPurify.sanitize(item.seller)}
          sellerEmail={item.sellerEmail}
          hideButton
        />
      ))}
      {!hideCheckbox && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isOrderClosed}
              onChange={handleCloseOrder}
              color="primary"
              disabled={isOrderClosed}
            />
          }
          label="Order Closed"
        />
      )}
      <p>Bought By: {collectionName === 'orders' ? 'You' : DOMPurify.sanitize(order.data.boughtBy)}</p>
      <CurrencyFormat
        renderText={(value) => (
          <h3 className='order__total'>Order Total (After Fees): {value}</h3>
        )}
        decimalScale={2}
        value={order.data.amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
      <Dialog open={showConfirmation} onClose={cancelCloseOrder}>
        <DialogTitle>Confirm Order Closure</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '16px' }}>
            <p>Are you sure you want to close this order? This action cannot be undone.</p>
            <p>&nbsp;</p>
            <p>Please rate your seller from 1-5:</p>
          </div>   
          <div>
            {[1, 2, 3, 4, 5].map((value) => (
              <Button
                key={value}
                onClick={() => handleRatingClick(value)}
                variant={selectedRating === value ? "contained" : "outlined"}
                color={selectedRating === value ? "primary" : "default"}
                style={{ marginRight: '8px' }}
              >
                {value}
              </Button>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelCloseOrder} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmCloseOrder} color="primary" disabled={selectedRating === null}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Order;