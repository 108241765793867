import React from "react";
import "./Home.css";
import Product from "./Product";
import louisville from "./images/louisville.jpeg";

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <img
          className="home__image"
          src={louisville}
          alt=""
        />

        <div className="home__row">
          
          <Product
            id="12321341"
            title="Desk Chiar"
            price={85.00}
            rating={4}
            image="https://m.media-amazon.com/images/I/71yxAKCrQrL._AC_UF894,1000_QL80_.jpg"
            seller="Test"
          />
          
          <Product
            id="49538094"
            title="Physics Textbook"
            price={89.00}
            rating={4}
            image="https://m.media-amazon.com/images/I/61RhN7E8UQL._AC_UF1000,1000_QL80_.jpg"
            seller="Test"
          />

          <Product
            id="49533358"
            title="Fan"
            price={29.99}
            rating={4}
            image="https://images.thdstatic.com/productImages/c39d3524-6bad-4dcc-b366-3677b14d746d/svn/black-hampton-bay-floor-fans-12102-64_1000.jpg"
            seller="Test"
          />
        </div>

        <div className="home__row">
          <Product
            id="49038500"
            title="Water Bottle"
            price={34.99}
            rating={3}
            image="https://target.scene7.com/is/image/Target/GUEST_c44ec556-14a8-484c-b46a-4a6399317a38?wid=488&hei=488&fmt=pjpeg"
            seller="Test"
          />
          <Product
            id="12123434"
            title="Wall Clock"
            price={22.00}
            rating={5}
            image="https://img.uline.com/is/image/uline/H-1436?$Mobile_Zoom$"
            seller="Test"
          />
          <Product
            id="60013810"
            title="10pack Hangers"
            price={8.00}
            rating={3}
            image="https://target.scene7.com/is/image/Target/GUEST_c1909419-7628-4d94-bbad-0d4f665af339?wid=488&hei=488&fmt=pjpeg"
            seller="Test"
          />
        </div>

        <div className="home__row">
          <Product
            id="20384412"
            title="Outlet Power Strip"
            price={6.18}
            rating={5}
            image="https://images.thdstatic.com/productImages/356d4bac-8d66-4c13-9824-2bd05c716a2b/svn/power-strips-ylpt-90-64_1000.jpg"
            seller="Test"
          />
          <Product
            id="20384301"
            title="Calculus Textbook"
            price={99.97}
            rating={4}
            image="https://m.media-amazon.com/images/I/61W2JSg+eiL._AC_UF1000,1000_QL80_.jpg"
            seller="Test"
          />

          <Product
            id="30712250"
            title="Chromebook"
            price={69.99}
            rating={3}
            image="https://i5.walmartimages.com/seo/HP-Chromebook-G7EE-11-6-Intel-Celeron-4100-4GB-Ram-16GB-SSD-Bluetooth-5-0-WIFI-Chrome-OS-Used_5024a5b3-3ffb-46ea-8cc8-7217d376d46c.b5e01052242d825d847a0c54dd30d526.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF"
            seller="Test"
          />



        </div>
      </div>
    </div>
  );
}

export default Home;