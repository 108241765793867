import React from 'react';
import "./Subtotal.css";
import CurrencyFormat from 'react-currency-format';
import { useStateValue } from "./StateProvider";
import { getBasketTotal, getBasketTotalWithFees } from './reducer';

function Subtotal({ handleCheckout, processing }) {
  const [{ basket }, dispatch] = useStateValue();

  return (
    <div className='subtotal'>
      <CurrencyFormat
        renderText={(value) => (
          <>
            <p>
              Total<span>*</span>: <strong>{value}</strong>
            </p>
            <small>*This total includes payment processing fees.</small>
          </>
        )}
        decimalScale={2}
        value={getBasketTotalWithFees(basket)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />

      <button onClick={handleCheckout} disabled={processing}>
        {processing ? 'Processing...' : 'Proceed to Checkout'}
      </button>
    </div>
  )
}

export default Subtotal;