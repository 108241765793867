import React, { useEffect, useState } from 'react'
import './Order.css'
import moment from "moment";
import CheckoutProduct from './CheckoutProduct';
import CurrencyFormat from 'react-currency-format';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { db } from "./firebase";
import { useStateValue } from './StateProvider';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

function ClosedOrder({ order }) {

  const [{ basket, user }, dispatch] = useStateValue();

  return (
    <div className='order'>
      <h2>Order</h2>
      <p>{moment.unix(order.data.created).format("MMMM Do YYYY, h:mma")}</p>
      <p className='order__id'>
        <small>{order.id}</small>
      </p>
      {order.data.basket?.map(item => (
        <CheckoutProduct
          id={item.id}
          title={item.title}
          image={item.image}
          price={item.price}
          rating={item.rating}
          seller={item.seller}
          sellerEmail={item.sellerEmail}
          hideButton
        />
      ))}

      <CurrencyFormat
        renderText={(value) => (
          <>
            <h3 className='order__total'>Order Total: {value}</h3>
          </>
        )}
        decimalScale={2}
        value={order.data.amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    </div>
  )
}

export default ClosedOrder