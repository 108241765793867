import React, { useEffect, useState } from 'react';
import './Orders.css';
import { db } from "./firebase";
import { useStateValue } from './StateProvider';
import Order from "./Order";

function Orders() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if(user) {
      db
        .collection('users')
        .doc(user?.email)
        .collection('soldOrders')
        .orderBy('created', 'desc')
        .onSnapshot(snapshot => (
          setOrders(snapshot.docs.map(doc => {
            const data = doc.data();
            // Calculate 95% of the original amount
            const adjustedAmount = data.amount * 0.95;
            return {
              id: doc.id,
              data: {
                ...data,
                originalAmount: data.amount,
                amount: adjustedAmount
              }
            };
          }))
        ))
    } else {
      setOrders([])
    }
  }, [user])

  return (
    <div className='orders'>
        <h1>Your Sold Orders</h1>
        <h1>All Orders Are Marked "Pending" Until Closed Out By Buyer</h1>
        <p>Note: The amounts shown reflect your earnings after the 5% commission.</p>

        <div className='orders__order'>
          {orders?.map(order => (
            <Order
              key={order.id}
              order={order}
              hideCheckbox
            />
          ))}
        </div>
    </div>
  )
}

export default Orders