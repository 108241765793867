import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';

function PrivateRoute({ children, requiredRole }) {
  const [{ user }] = useStateValue();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (requiredRole === 'bellarmine' && (!user.email.endsWith('@bellarmine.edu') || !user.emailVerified)) {
    return <Navigate to="/login" />;
  }

  if (requiredRole === 'louisville' && (!user.email.endsWith('@louisville.edu') || !user.emailVerified)) {
    return <Navigate to="/login" />;
  }

  if (requiredRole === 'verified' && !user.emailVerified) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default PrivateRoute;