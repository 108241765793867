import React, { useEffect, useState } from 'react';
import './Orders.css';
import { db } from "./firebase";
import { useStateValue } from './StateProvider';
import Order from "./Order";
import ClosedOrder from './ClosedOrder';

function ClosedOrders() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if(user) {
      db
    .collection('users')
    .doc(user?.email)
    .collection('closedOrders')
    .orderBy('created', 'desc')
    .onSnapshot(snapshot => (
      setOrders(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    ))
    } else{
      setOrders([])
    }
  }, [user])

  return (
    <div className='orders'>
        <h1>Your Closed Orders</h1>

        <div className='orders__order'>
          {orders?.map(order => (
            <ClosedOrder order={order} />
          ))}
        </div>
    </div>
  )
}

export default ClosedOrders