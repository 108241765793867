import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import "./ProductSubmit.css";
import { db } from './firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useStateValue } from "./StateProvider";
import axios from 'axios';
import DOMPurify from 'dompurify'; // For input sanitization

const FIREBASE_FUNCTIONS_URL = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

// Set up CSRF protection
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';
const ProductSubmit = () => {
  console.log('FIREBASE_FUNCTIONS_URL:', FIREBASE_FUNCTIONS_URL);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [sellerEmail, setSellerEmail] = useState('');
  const [image, setImage] = useState(null);
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasActiveStripeConnect, setHasActiveStripeConnect] = useState(false);
  const [isSettingUpStripe, setIsSettingUpStripe] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;}
      const checkStripeConnectStatus = async () => {
        if (user && user.email) {
          setSellerEmail(user.email);
          try {
            const response = await axios.get(`${FIREBASE_FUNCTIONS_URL}/checkStripeConnect?userEmail=${user.email}`);
            console.log('Stripe Connect status from Firebase:', response.data);
            let isActive = response.data.hasStripeConnect && response.data.stripeConnectStatus === 'active';
            if (!isActive) {
              try {
                const manualCheckResponse = await axios.get(`${FIREBASE_FUNCTIONS_URL}/checkAndUpdateAccountStatus?email=${user.email}`);
                console.log('Manual check result:', manualCheckResponse.data);
                isActive = manualCheckResponse.data.status === 'active';
              } catch (manualCheckError) {
                console.error('Error during manual check:', manualCheckError);
                // Don't throw error here, just log it
              } }
              setHasActiveStripeConnect(isActive);
              const urlParams = new URLSearchParams(window.location.search);
              if (urlParams.get('stripe_onboarding') === 'success') {
                alert('Stripe Connect account setup successful!');
              }
            } catch (error) {
              console.error('Error checking Stripe Connect:', error);
              // Don't set hasActiveStripeConnect to false here
            } finally {
              setIsLoading(false);
            }
          }
        };
      
        checkStripeConnectStatus();
      }, [user, navigate]);  const handleError = useCallback((error, message) => {
        console.error(message, error);
        alert(`An error occurred: ${message}. Please try again later.`);
      }, []);
      const validateInput = useCallback((title, price) => {
        if (title.length < 3 || title.length > 100) {
          throw new Error("Title must be between 3 and 100 characters");
        }
        if (isNaN(price) || price <= 0 || price > 10000) {
          throw new Error("Price must be a valid number between 0 and 10000");
        }
      }, []);
      const validateImage = useCallback((file) => {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (!validTypes.includes(file.type)) {
          throw new Error("Invalid file type. Please upload a JPEG, PNG, or GIF image.");}
          if (file.size > maxSize) {
            throw new Error("File size exceeds 5MB limit.");
          }
        }, []);
        const handleSetupStripeConnect = async () => {
          setIsSettingUpStripe(true);
        
          try {
            console.log('Calling createStripeConnectAccount function...');
            const createResponse = await axios.get(`${FIREBASE_FUNCTIONS_URL}/createStripeConnectAccount?email=${user.email}`);
            console.log('Response from createStripeConnectAccount:', createResponse.data);
            
            if (createResponse.data.success) {
              console.log('Redirecting to:', createResponse.data.onboardingUrl);
              window.location.href = createResponse.data.onboardingUrl;
            } else {
              throw new Error(createResponse.data.error || 'Failed to create Stripe Connect account');
            }} catch (error) {
              handleError(error, 'Error setting up Stripe Connect');
            } finally {
              setIsSettingUpStripe(false);
            }
          };
          const handleSubmit = async (e) => {
            e.preventDefault();
            if (isSubmitting) return;
          
            setIsSubmitting(true);
            console.log('Starting submission process');
          
            try {
              console.log('Validating input');
              console.log('Title:', title);
              console.log('Price:', price);
              validateInput(title, price);
              
              if (!image) {
                throw new Error('Please select an image for the product.');
              }   
              console.log('Image selected:', image.name);
              validateImage(image);
          
              console.log('Uploading image');
              const storage = getStorage();
              const storageRef = ref(storage, `images/${image.name}`);
              const uploadTask = uploadBytesResumable(storageRef, image);
          
              await new Promise((resolve, reject) => {
                uploadTask.on(
                  'state_changed',
                  (snapshot) => {
                    const progress = Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    console.log(`Upload is ${progress}% done`);
                  },
                  (error) => {
                    console.error('Upload error:', error);
                    reject(error);
                  },
                  () => {
                    console.log('Upload completed');
                    resolve();
                  }
                );
              });
          
              console.log('Getting download URL');
              const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              console.log('Image URL:', imageUrl);
          
              console.log('Getting user ID token');
              const idToken = await user.getIdToken();
              console.log('ID token obtained (first 20 chars):', idToken.substring(0, 20) + '...');
          
              console.log('Preparing payload for submitProduct');
              const payload = {
                title,
                price: parseFloat(price),
                imageUrl,
                sellerEmail
              };
              console.log('Payload:', payload);
          
              console.log('Calling submitProduct function');
              console.log('URL:', `${FIREBASE_FUNCTIONS_URL}/submitProduct`);
              console.log('Payload being sent:', JSON.stringify(payload, null, 2));
              const response = await axios.post(
                `${FIREBASE_FUNCTIONS_URL}/submitProduct`,
                payload,
                {
                  headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                  }
                }
              );
          
              console.log('Response from submitProduct:', response.data);
          
              if (response.data.success) {
                setTitle('');
                setPrice('');
                setImage(null);
                alert('Product added successfully!');
              } else {
                throw new Error('Failed to submit product');
              }
            } catch (error) {
              console.error('Error in handleSubmit:', error);
              if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
              } else if (error.request) {
                console.error('Error request:', error.request);
              } else {
                console.error('Error message:', error.message);
              }
              handleError(error, 'Error submitting product');
            } finally {
              setIsSubmitting(false);
              console.log('Submission process completed');
            }
          };
          if (isLoading) {
            return <div>Loading...</div>;
          }
          if (!hasActiveStripeConnect) {
            return (
              <div className="form-container">
                <div className="stripe-connect-message">
                  <p>You need an active Stripe Connect Account to submit a product.</p>
                  {isSettingUpStripe ? (
                    <p>Setting up Stripe Connect...</p>
                  ) : (
                    <button onClick={handleSetupStripeConnect}>
                      Set up Stripe Connect
                    </button>
                  )}
                  <p className="important-note"><strong>Typical onboarding takes about 5 minutes. Please leave all pre-filled fields filled in.</strong></p>
                  <p>If you have already set up Stripe Connect, please try refreshing the page.</p>
                  <p>If refreshing doesn't work, Stripe may need additional information for your account setup. This is for security and safety reasons.</p>
                  <p className="phone-number-note"><strong>Use the same phone number for your customer support field that Stripe asks for as your personal phone number.</strong></p>
                  <p>If you think you have done everything in the setup correctly, you may need to wait a couple minutes or refresh multiple times as Stripe processes your account setup request.</p>
                  <p>If you continue to experience issues, please contact support.</p>
                </div>
              </div>
            );
          }
          return (
            <div className="form-container">
              <h2>Submit a Product</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="title">Title:</label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="price">Price ($):</label>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    min="0.01"
                    step="0.01"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sellerEmail">Seller Email:</label>
                  <input
                    type="email"
                    id="sellerEmail"
                    value={sellerEmail}
                    disabled
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="image">Product Image:</label>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                    required
                  />
                </div>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit Product'}
                </button>
              </form>
              <p className="important-message">
             <strong>
             Important: After submitting your product, please keep an eye on your email (including your junk/spam folder) for messages from potential buyers. You can also communicate with buyers through the "Chats" tab in your account.
            </strong>
            </p>
            </div>
          );
        };
        export default ProductSubmit;