import React, { useState } from 'react';
import { useStateValue } from "./StateProvider";
import './Contact.css'

export const ProductSubmit = () => {
  const [{ user }] = useStateValue();
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/submitComplaint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email: user?.email, message }),
      });

      if (response.ok) {
        console.log('Message submitted successfully');
        setIsSubmitted(true);
        alert('Your message has been sent!');
        setName('');
        setMessage('');
      } else {
        throw new Error('Failed to submit message');
      }
    } catch (error) {
      console.error('Error submitting message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <form onSubmit={sendMessage} className="product-submit-form">
      <label>Name</label>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
      <label>Email</label>
      <input type="email" value={user?.email || ''} readOnly />
      <label>Message</label>
      <textarea value={message} onChange={(e) => setMessage(e.target.value)} required />
      <input type="submit" value="Send" />
      <h1>Input your first and last name and a summary of your concern</h1>
      <h2>The team will be in contact within 24 hours</h2>
    </form>
  );
};

export default ProductSubmit;