import React, { useState, useEffect } from 'react';
import './Login.css';
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "./firebase";
import img1 from "./images/block_nerd.png";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const rememberedUser = localStorage.getItem('rememberedUser');
    if (rememberedUser) {
      const { email, password } = JSON.parse(rememberedUser);
      setEmail(email);
      setPassword(password);
      setRememberMe(true);
    }
  }, []);

  const signIn = async (e) => {
    e.preventDefault();

    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password);

      if (user.emailVerified) {
        const userDocRef = db.collection("users").doc(user.email);
        const userDoc = await userDocRef.get();

        if (!userDoc.exists) {
          await auth.signOut();
          alert("Your account has been deleted. Please contact support if you believe this is an error.");
          return;
        }

        if (rememberMe) {
          localStorage.setItem('rememberedUser', JSON.stringify({ email, password }));
        } else {
          localStorage.removeItem('rememberedUser');
        }

        if (email.includes("@bellarmine.edu")) {
          navigate('/bellarmine');
        } else if (email.includes("@louisville.edu")) {
          navigate('/louisville');
        }
      } else {
        alert("Please verify your email before continuing. This email can take up to 5 minutes to receive.");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const register = e => {
    e.preventDefault();

    if (email.includes("bellarmine.edu")) {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((auth) => {
          const user = auth.user;
          user.sendEmailVerification();
          alert("Please verify your email before continuing. This verification can take up to 5 minutes to receive.");
          db.collection("users").doc(user.email).set({
            email: user.email,
            stripe_connect_id: null,
          });
          console.log(auth);
        })
        .catch(error => alert(error.message));
    } else {
      alert("Please use a valid Bellarmine email address.");
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      alert("Please enter your email address.");
      return;
    }

    try {
      const userDoc = await db.collection("users").doc(resetEmail).get();
      if (!userDoc.exists) {
        alert("No account found with this email address.");
        return;
      }

      await auth.sendPasswordResetEmail(resetEmail);
      alert("Password reset email sent. Please check your inbox.");
      setResetEmail('');
      setShowResetPassword(false);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className='login'>
      <Link to='/'>
        <img 
         className="login__logo"
         src={img1} 
         alt="Logo"
        />
      </Link>

      <div className='login__container'>
          <h1>Sign-in or Create an Account</h1>

          <form>
              <h5>E-mail</h5>
              <input type='text' value={email} onChange={e => setEmail(e.target.value)}/>

              <h5>Password</h5>
              <input type='password' value={password} onChange={e => setPassword(e.target.value)}/>

              <div className="login__rememberMe">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={e => setRememberMe(e.target.checked)}
                />
                <label>Remember Me</label>
              </div>

              <button 
              type='submit' onClick={signIn}
              className='login__signInButton'>Sign In
              </button>
          </form>

          <button 
          onClick={register}
          className='login__registerButton'>Create your StuShop Account
          </button>

          {!showResetPassword ? (
            <button 
              onClick={() => setShowResetPassword(true)}
              className='login__forgotPasswordButton'>
              Forgot Password?
            </button>
          ) : (
            <div className='login__resetPasswordContainer'>
              <h5>Enter your email to reset password</h5>
              <input 
                type='email' 
                value={resetEmail} 
                onChange={e => setResetEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <button 
                onClick={handlePasswordReset}
                className='login__resetPasswordButton'>
                Reset Password
              </button>
              <button 
                onClick={() => setShowResetPassword(false)}
                className='login__cancelResetButton'>
                Cancel
              </button>
            </div>
          )}
      </div>
    </div>
  );
}

export default Login;