import React, { useState, useEffect } from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteDoc, doc } from "firebase/firestore";

function Product({ id, title, image, price, sellerEmail, userEmail, onDelete }) {
  const [{ basket, searchQuery }, dispatch] = useStateValue();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [sellerData, setSellerData] = useState(null);

  const isProductVisible =
    searchQuery !== undefined
      ? title.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const sellerRef = db.collection("users").doc(sellerEmail);
        const sellerDoc = await sellerRef.get();
        if (sellerDoc.exists) {
          setSellerData(sellerDoc.data());
        } else {
          console.log("No such seller found");
        }
      } catch (error) {
        console.error("Error fetching seller data:", error);
      }
    };

    fetchSellerData();
  }, [sellerEmail]);

  const addToBasket = () => {
    const isSameSeller =
      basket.every((item) => item.sellerEmail === sellerEmail) ||
      basket.length === 0;

    if (isSameSeller) {
      const existingProduct = basket.find((item) => item.id === id);

      if (!existingProduct) {
        dispatch({
          type: "ADD_TO_BASKET",
          item: {
            id: id,
            title: title,
            image: image,
            price: price,
            sellerEmail: sellerEmail,
          },
        });
      } else {
        alert("Product already in cart.");
      }
    } else {
      alert("You can only add items from the same seller to the cart.");
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const productRef = doc(db, "bellarmine", id);
      await deleteDoc(productRef);
      onDelete(id);
      setShowConfirmDelete(false);
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Failed to delete product. Please try again.");
    }
  };

  if (!isProductVisible) {
    return null;
  }

  return (
    <div className="product">
      {sellerEmail === userEmail && (
        <div className="product__deleteIcon" onClick={handleDeleteClick}>
          <DeleteIcon />
        </div>
      )}
      {showConfirmDelete && (
        <div className="product__confirmDelete">
          <p>Are you sure you want to remove this product?</p>
          <button onClick={handleConfirmDelete}>Yes</button>
          <button onClick={() => setShowConfirmDelete(false)}>No</button>
        </div>
      )}
      <div className="product__info">
        <p>{title}</p>
        <p className="product__price">
          <small>$</small>
          <strong>{price}</strong>
        </p>
        <p>Seller: {sellerEmail}</p>
        {sellerData && (
          <p>
            Average Rating:{" "}
            {sellerData.averageRating
              ? `${sellerData.averageRating.toFixed(2)}/5`
              : "N/A"}{" "}
            {sellerData.ordersSoldWithRating
              ? `(${sellerData.ordersSoldWithRating} orders)`
              : ""}
          </p>
        )}
      </div>

      <img src={image} alt="" />

      <button onClick={addToBasket}>Add to Cart</button>
    </div>
  );
}

export default Product;