import React, { useState, useEffect } from "react";
import './App.css';
import Header from './Header';
import BellarmineHome from './BellarmineHome';
import LouisvilleHome from './LouisvilleHome';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Checkout from "./Checkout";
import Login from "./Login";
import { auth, getCurrentUserIdToken } from "./firebase";
import { useStateValue } from "./StateProvider";
import { loadStripe } from "@stripe/stripe-js";
import ProductSubmit from "./ProductSubmit";
import Orders from "./Orders";
import Chats from "./Chats";
import { AuthProvider } from "./contexts/AuthContext";
import ClosedOrders from "./ClosedOrders";
import SoldOrders from "./SoldOrders";
import Contact from "./Contact";
import StripeConnectSetup from './StripeConnectSetup';
import PrivateRoute from './PrivateRoute';

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const [{ user }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const idToken = await getCurrentUserIdToken();
        localStorage.setItem('authToken', idToken);
        dispatch({
          type: 'SET_USER',
          user: authUser,
        });
      } else {
        localStorage.removeItem('authToken');
        dispatch({
          type: 'SET_USER',
          user: null,
        });
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <AuthProvider>
        <div className="app">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/checkout" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <Checkout />
              </PrivateRoute>
            } />
            <Route path="/bellarmine" element={
              <PrivateRoute requiredRole="bellarmine">
                <Header />
                <BellarmineHome />
              </PrivateRoute>
            } />
            <Route path="/louisville" element={
              <PrivateRoute requiredRole="louisville">
                <Header />
                <LouisvilleHome />
              </PrivateRoute>
            } />
            <Route path="/contact" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <ProductSubmit />
              </PrivateRoute>
            } />
            <Route path="/orders" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <Orders />
              </PrivateRoute>
            } />
            <Route path="/chats" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <Chats />
              </PrivateRoute>
            } />
            <Route path="/closedorders" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <ClosedOrders />
              </PrivateRoute>
            } />
            <Route path="/contactUs" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <Contact />
              </PrivateRoute>
            } />
            <Route path="/soldorders" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <SoldOrders />
              </PrivateRoute>
            } />
            <Route path="/stripe-connect-setup" element={
              <PrivateRoute requiredRole="verified">
                <Header />
                <StripeConnectSetup />
              </PrivateRoute>
            } />
            <Route path="/" element={<Login />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;