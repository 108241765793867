const STRIPE_FEE_PERCENTAGE = 0.029;
const STRIPE_FIXED_FEE = 0.30;
const KY_TAX_RATE = 0.06; // 6% tax rate

export const initialState = {
    basket: [],
    user: null
};

export const getBasketTotal = (basket) => {
    return basket?.reduce((amount, item) => item.price + amount, 0);
};

export const getBasketTotalWithFees = (basket) => {
    const subtotal = getBasketTotal(basket);
    const tax = subtotal * KY_TAX_RATE;
    const stripeFee = subtotal * STRIPE_FEE_PERCENTAGE + STRIPE_FIXED_FEE;
    return subtotal + tax + stripeFee;
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_BASKET':
            return {
                ...state,
                basket: [...state.basket, action.item],
            };

        case 'REMOVE_FROM_BASKET':
            const index = state.basket.findIndex(
                (basketItem) => basketItem.id === action.id
            );
            let newBasket = [...state.basket];

            if (index >= 0) {
                newBasket.splice(index, 1);
            } else {
                console.warn(
                    `Can't remove product (id: ${action.id}) as it's not in the basket!`
                )
            }

            return {
                ...state,
                basket: newBasket
            }

        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }

        case 'EMPTY_BASKET':
            return {
                ...state,
                basket: []
            }

        default:
            return state;
    }
};

export default reducer;
